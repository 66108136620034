<template>
    <section>
      <section class="">

        <Breadcrumb
          :links="[
            {
              label: 'Back',
              link: true,
              route: { name: 'Instructor Grading Course' , params: { id: $route.params.id }, query: { panel: 2}},
            },
            {
              label: `${title}`,
              link: false,
              route: null,
            },
          ]"
        />
        <!-- <v-btn text class="poppins" color="primary" dense @click="$router.push()">
          <v-icon small>
            mdi-chevron-left
          </v-icon>
          Back
        </v-btn> -->
        <!-- /
        <Breadcrumb
          class="mt-1"
          :links="[
            {
              label: `${title}`,
              link: false,
              route: null,
            },
          ]"
        /> -->
      </section>
      <v-row no-gutters dense>
        <v-col cols="12" md="10">
          <v-tabs
            v-model="tab"
            background-color="transparent"
            show-arrows
            color="primary"
            active-class="active"
            dense
          >
          <v-tab
              v-for="item in survey"
              :key="item"
              class="poppins text-capitalize f14 fw600"
              active-class="black--text"
            >
              {{ item }}
            </v-tab>
          </v-tabs>
        </v-col>
        <v-col cols="12" md="2">
          <v-btn dense outlined color="primary" class="poppins float-right mt-3 mr-3" v-if="tab === 1" @click="exportSummary" :loading="exportLoading">
            EXPORT
          </v-btn>
        </v-col>
      </v-row>

      <v-progress-linear
        v-if="loading && tab !== 0"
            indeterminate
            color="primary"
            rounded
            height="4"
            class="my-3"
      ></v-progress-linear>

      <v-tabs-items v-model="tab" style="background-color: transparent" class="py-2">
        <v-tab-item v-for="item in survey" :key="item" style="background-color: transparent">
            <SurveyIndividual 
              v-if="tab === 0" 
              class="mx-2" 
              :loading="loading"
              :page="page"
              :paginate="paginate"
              :pageCount="pageCount"
              :learners="learners"
              @search="(e) => {search = e, getRespondents()}"
              @page="(e) => {page = e, getRespondents()}"
              @paginate="(e) => {paginate = e, getRespondents()}"/>
            <SurveySummary v-if="tab === 1" class="mx-3"/>
            <SurveyQuestion v-if="tab === 2" class="mx-3" :loading="loading"/>
        </v-tab-item>
      </v-tabs-items>
    </section>
  </template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import survey from '@/constants/tabs/survey/survey';
import SurveySummary from '@/components/teacher/survey/SurveySummary.vue';
import SurveyQuestion from '@/components/teacher/survey/SurveyQuestion.vue';
import SurveyIndividual from '@/components/teacher/survey/SurveyIndividual.vue';
import Breadcrumb from "@/layouts/teacher/InstructorBreadcrumbs.vue";

export default {
  components: {
    SurveyIndividual,
    SurveyQuestion,
    SurveySummary,
    Breadcrumb
  },
  data:() => ({
      survey,
      loading: false,
      users: [],
      no_choices: ['file_upload', 'date', 'time', 'short_answer', 'paragraph'],
      pageCount: 1,
      page: 1,
      paginate: '10',
      search: '',
      learners: [],
      tab: 0,
      title: '',
      exportLoading: false
  }),
  methods: {
    ...mapActions('instructor', ['showCourseEvaluationAction', 'showModuleEvaluationAction',
      'getCourseEvaluationQuestionsAction', 'getModuleEvaluationQuestionsAction', 
      'getCourseEvaluationRespondentsAction', 'getModuleEvaluationRespondentsAction', 
      'getCourseEvaluationQuestionAnswers', 'getModuleEvaluationQuestionAnswers',
      'getCourseEvaluationAnswers', 'getModuleEvaluationAnswers',
      'exportCourseEvaluationSummary', 'exportModuleEvaluationSummary']),
    ...mapMutations('instructor', ['courseEvaluationsMutation', 'evaluationAnswersMutation', 'moduleEvaluationsMutation']),

    exportSummary(){
      this.exportLoading = true
      if(this.$route.params.type === 'course_evaluation') {
        this.exportCourseEvaluationSummary({ course_evaluation_id: this.$route.params.survey_id, filter: '' }).finally(() => {
          this.exportLoading = false
        })
      }
      if(this.$route.params.type === 'module_evaluation') {
        this.exportModuleEvaluationSummary({ module_evaluation_id: this.$route.params.survey_id, filter: '',  }).finally(() => {
          this.exportLoading = false
        })
      }
    },

    getQuestions(){
      this.loading = true
      if(this.$route.params.type === 'course_evaluation') {
        this.getCourseEvaluationQuestionsAction(this.$route.params.survey_id).then(res => {
          this.title = res.title
          if(this.tab === 2) {
            this.loading = true
            this.getCourseEvaluationAnswers(this.$route.params.survey_id).finally(() => {
              this.loading = false
            })
          } else {
            this.loading = false
          }
        }).catch(() => {
          this.loading = false
        })
      }

      if(this.$route.params.type === 'module_evaluation') {
        this.getModuleEvaluationQuestionsAction(this.$route.params.survey_id).then(res => {
          this.title = res.title
          if(this.tab === 2) {
            this.loading = true
            this.getModuleEvaluationAnswers(this.$route.params.survey_id).finally(() => {
                this.loading = false
            })
          } else {
            this.loading = false
          }
        }).catch(() => {
          this.loading = false
        })
      }
    },

    getRespondents(){
      this.loading = true
      if(this.$route.params.type === 'course_evaluation') {
        this.getCourseEvaluationRespondentsAction({ id:this.$route.params.survey_id, page: this.page, paginate: Number(this.paginate), search: this.search}).then(res => {
          this.learners = res.data
          this.pageCount = res.last_page
          this.paginate = String(res.per_page)
          this.page = res.current_page
          this.search = this.search
        }).then(() => {
          this.loading = false
        }).catch(() => {
          this.loading = false
        })
      }
      if(this.$route.params.type === 'module_evaluation') {
        this.getModuleEvaluationRespondentsAction({ id:this.$route.params.survey_id, page: this.page, paginate: Number(this.paginate), search: this.search}).then(res => {
          this.learners = res.data
          this.pageCount = res.last_page
          this.paginate = String(res.per_page)
          this.page = res.current_page
          this.search = this.search
        }).then(() => {
          this.loading = false
        }).catch(() => {
          this.loading = false
        })
      }
    }

  },
  created(){
    
  },
  mounted() {
    this.getQuestions()
    this.getRespondents()
  },
  computed: {
    ...mapState('instructor', {
        questions: (state) => state.questions,
        evaluation_answers: (state) => state.evaluation_answers,
    }),
  },
  watch:{
    tab(val) {
      if(val === 0) {
        this.getQuestions()
        this.getRespondents()
      }
      if(val === 2){
        this.loading = true
        this.getQuestions()
      }
    }
  }
}

</script>
