<template>
    <v-container class="d-flex flex-wrap overflow-y-auto align-start" :style="'height: 250px'">
        <span v-for="answer in answers" v-if="type === 'date'" color="primary" class="mr-3 my-2 pa-1 f14 border px-8 my-3" :style="`background-color: #F2F2F2;`"> {{ $dateFormat.mmDDyy(answer) }} </span>
        <span v-for="answer in answers" v-if="type === 'time'" color="primary" class="mr-3 my-2 pa-1 f14 border" :style="`background-color: #F2F2F2;`"> <input type="time" readonly :value="answer" class="ml-8"/> </span>
        <span v-for="answer in answers" v-if="type === 'short_answer' || type === 'paragraph'" color="primary" class="mr-3  my-2 pa-2 f14 border" :style="`background-color: #F2F2F2;`"> {{ answer }} </span>
        <span v-for="answer in answers" v-if="type === 'link'" color="primary" class="mr-3 my-2 pa-2 f14 border" :style="`background-color: #F2F2F2;`"> <a :href="answer" target="_blank"> {{ answer }} </a> </span>
    </v-container>
</template>

<script>
export default {
    props: ['type', 'answers']
}
</script>